import './App.css';
import { Layout, Typography, message, Flex } from 'antd';
import { createContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import DemoPage from '../demo/demopage';
import AdminPanel from '../../components/admin/adminpanel';
import LKMainPage from '../lk/lkmainpage';
import HeaderComponent from '../../components/header/header';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from '../../config';
import MainPage from '../main/mainPage';
import ApiPage from '../api/apiPage';
import PromptsPage from '../prompts/promptsPage';
import cross from '../../assets/cross.svg';
import trash from '../../assets/trash-popup.svg';

export const MessageContext = createContext(null);

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  backgroundColor: '#efefef00',
};

const App = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();

  const show_error = (text) => {
    messageApi.error(text);
  };

  const show_success = (text) => {
    messageApi.success(text);
  };

  useEffect(() => {
    if (location.pathname === '/auth/callback') {
      axios
        .get(apiURL + location.pathname + location.search)
        .then((res) => {
          console.log(res.data);
          window.location.href = '/';
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [location.pathname]);

  return (
    <MessageContext.Provider value={[show_error, show_success]}>
      <Layout
        style={{
          overflowX: 'hidden',
          width: 'calc(100%)',
          maxWidth: 'calc(100%)',
          height: '100vh',
          maxHeight: '100vh',
          minHeight: '100vh',
          background: 'transparent',
        }}
      >
        {window.location.pathname !== '/' && <HeaderComponent />}
        <Content style={contentStyle}>
          <Routes>
            <Route exact path={'/'} element={<DemoPage />} />
            <Route exact path={'/admin'} element={<AdminPanel />} />
            <Route exact path={'/lk'} element={<LKMainPage />} />
            <Route exact path={'/main'} element={<MainPage />} />
            <Route exact path={'/data_api'} element={<ApiPage />} />
            <Route exact path={'/prompts'} element={<PromptsPage />} />
          </Routes>
          <Flex className="overlay">
            <Flex vertical align="center" gap={0} className="popup">
              <Flex
                vertical={false}
                justify="flex-end"
                style={{ width: '100%' }}
              >
                <img
                  src={cross}
                  className="close-icon"
                  alt="cross"
                  onClick={() =>
                    (document.querySelector('.overlay').style.display = 'none')
                  }
                />
              </Flex>
              <Flex
                vertical
                align="center"
                gap={0}
                style={{ width: '100%', padding: '0 12px' }}
              >
                <img
                  src={trash}
                  className="trash-icon"
                  alt="trash"
                />
                <h1 className="confirmation-message">
                  Вы уверены, что хотите удалить промт?
                </h1>
                <p className="confirmation-text">
                  Отменить действие будет невозможно
                </p>
                <button className="delete-popup">
                  Удалить
                </button>

                <button
                  className="cancel-popup"
                  onClick={() =>
                    (document.querySelector('.overlay').style.display = 'none')
                  }
                >
                  Отменить
                </button>
              </Flex>
            </Flex>
          </Flex>
        </Content>
        {contextHolder}
      </Layout>
    </MessageContext.Provider>
  );
};

export default App;
