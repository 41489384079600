import { Upload, message } from 'antd';
import { apiURL } from '../../config';
import upload from '../../assets/upload.svg';
import './uploader.css';

const { Dragger } = Upload;

const ImportAudio = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { setUuid, setFilename, setFileSize, disabled = false } = props;

    const upload_props = {
        style: { width: '100%' },
        maxCount: 1,
        name: 'file',
        action: apiURL + '/demo/analyze_audio?uuid=stand_uuid',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setUuid(info.file.response.task_id);
                setFilename(info.file.name);
                setFileSize(info.file.size / Math.pow(1024, 2));
                messageApi.success(`Аудиофайл загружен. Ожидайте окончания анализа.`);
            } else if (status === 'error') {
                messageApi.error(`Ошибка при загрузке аудиофайла ${info.file.name}.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Dragger
            {...upload_props}
            style={{
                margin: 0,
                width: '100%',
                height: '100%',
                border: '1px dashed rgba(85, 85, 85, 1)',
            }}
            disabled={disabled}
        >
            {contextHolder}
            <p className="uploader-hint">
                Перетащите сюда файл или{' '}
                <span>загрузите его здесь.</span>{' '}
                <br />
                Формат файла: .mp3, .ogg
            </p>
        </Dragger>
    );
};

export default ImportAudio;