import { Layout } from 'antd';
import mainlogo from '../../assets/mainlogo.svg';
import './header.css';

import { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from '../../config';

const { Header } = Layout;

const HeaderComponent = () => {
    const navigate = useNavigate();
    const data = [
        {
            id: 1,
            name: 'Главная',
            route: '/main',
        },
        {
            id: 2,
            name: 'Демо',
            route: '/',
        },
        {
            id: 3,
            name: 'API',
            route: '/data_api',
        },
        {
            id: 4,
            name: 'Промпты',
            route: '/prompts',
        },
        {
            id: 5,
            name: 'Выйти из ЛК',
            route: '/',
        },
    ];
    const location = useLocation();
    const [isActive, setIsActive] = useState(
        data.find((item) => item.route === location.pathname)?.id ?? 2
    );
    const logout = () => {
        axios
            .get(apiURL + '/logout')
            .then((res) => {
                console.log(res.data);
                window.location.href = '/';
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const handleNavigation = (id, route) => {
        navigate(route);
        setIsActive(id);
    };
    const width = window.innerWidth;

    return (
        <Layout className="header-container">
            <Header className={`header ${width < 500 ? 'header-mobile' : ''}`}>
                <div className="logo-container">
                    <img height={28} src={mainlogo} alt="logo1" />
                </div>

                <div className={`nav-container ${width < 500 ? 'nav-container-mobile' : ''}`}>
                    {data.map((item) => (
                        <div
                            className={`nav-item ${isActive === item.id ? 'nav-item-active' : ''}`}
                            onClick={() => {
                                if (item.route === '/') {
                                    logout();
                                } else {
                                    handleNavigation(item.id, item.route);
                                }
                            }}
                            key={item.id}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            </Header>
        </Layout>
    );
};

export default HeaderComponent;